import React, { useEffect } from "react"
import Layout from "../ui/Layout"
import { navigate } from "gatsby"
import connectStore from "../state/connectStore"
import { fetchOrder } from "../util/OrderUtil"
import { useQueryParam, StringParam } from "use-query-params"

const Page = ({ location, setOrder }) => {
  const [orderId] = useQueryParam("orderId", StringParam);

  // This is equivalent to "componentDidMount"
  // If this page just mounted and we don't have a valid order id,
  // try to derive one from the url args.
  useEffect(() => {
    fetchOrder({
      orderId,
      onSuccess: order => {
        setOrder(order);
        navigate("/order-cookie", { replace: true })
      },
      onFailure: () => {
        console.log("Failure: " + orderId);
        navigate("/order-qr-reader", { replace: true })
      },
    });
  }, [orderId, setOrder]);

  return (
    <Layout location={location} validate={false} showOrder={false} showBack={false}/>
  );
};

export default connectStore(Page);
